import gsap from "gsap";

export default {

    noWidows(text) {
        if (text && typeof text === "string") {
            return text.replace(/\s(?=[^\s]*$)/g, "&nbsp;");
        }

        return text;
    },

    parallaxIt(e, container, target, movement) {
        let relX = e.pageX - container.offsetLeft;
        let relY = e.pageY - container.offsetTop;

        gsap.to(target, {
            x: (relX - container.clientWidth / 2) / container.clientWidth * movement,
            y: (relY - container.clientHeight / 2) / container.clientHeight * movement,
            duration: 1
        });
    }

}