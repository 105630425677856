import React, {useRef} from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import styles from "./layout.module.scss";
import Footer from "./footer";
import desktopBg from "../assets/images/bg-hero.svg";

const Layout = (props) => {

    const bgRef = useRef(null);

    const handleMouseMove = (e) => {
        //let container = document.getElementById('hero');
        //globals.parallaxIt(e, container, bgRef.current, -15);
    };

    const Content = (
        <>
            <Header />
            <main>{props.children}</main>
            <Footer />
        </>
    );

    return (
        <div className={styles.containerFluid}>
            {props.isBasic ? Content :
                <React.Fragment>
                    <div className="d-none d-lg-block" onMouseMove={handleMouseMove}>
                        <div ref={bgRef} className={styles.background}>
                            <img src={desktopBg} alt="Simplifying the hostel experience" className={styles.image} />
                        </div>
                        <div className={styles.content}>
                            {Content}
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                        {Content}
                    </div>
                </React.Fragment>
            }
        </div>
    );

};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    isBasic: PropTypes.bool
};

export default Layout
