import React, { useRef, useEffect } from "react";
//import logo from "../assets/images/logo-with-white.png";
import logo from "../assets/images/logo.png";
import { graphql, useStaticQuery } from "gatsby";
import { gsap } from "gsap";
import styles from "./logo.module.scss";

const Logo = (props) => {

    const name = 'hostelify';
    const titleRef = useRef(null);
    const logoRef = useRef(null);

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    );

    useEffect(() => {
        if (titleRef.current) {
            let tl = gsap.timeline({paused: true}),
                items = [...titleRef.current.querySelectorAll('span')];
            tl.to(items, {opacity: 1, y: 0, duration: 1, stagger: 0.1, ease: 'elastic.out(0.7, 0.3)'});
            tl.to(items, {color: '#656565', duration: 0.3, stagger: 0.1}, 0.3);

            setTimeout(() => {
                tl.play();
            }, 500);
        }
    }, []);

    return (
        <a href="/" className="d-inline-flex align-items-center">
            <img ref={logoRef} src={logo} alt={site.siteMetadata.title} width="50px" />
            <div ref={titleRef} className={styles.text}>
                {name.split('').map((item, key) => {
                    return <span key={key} className={styles.textItem}>{item}</span>;
                })}
            </div>
        </a>
    );

};

export default Logo;