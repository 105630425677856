import React from "react"
import styles from "./header.module.scss";
import Logo from "./logo";
import Button from "./button";
import Section from "../layout/section";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "gsap/src/ScrollToPlugin";
import { useMedia } from "react-use";

gsap.registerPlugin(ScrollToPlugin);

const Header = () => {

    const isMobile = useMedia('(max-width: 992px)');

    const handleClick = () => {
        if (typeof window !== 'undefined') {
            if (isMobile) {
                gsap.to(window, {duration: 2, scrollTo: (document.body.scrollHeight - 800)});
            } else {
                gsap.to(window, {duration: 2, scrollTo: document.body.scrollHeight});
            }
        }
    };

    const handleScroll = (event, target) => {
        event.preventDefault();
        if (typeof window !== 'undefined') {
            gsap.to(window, {duration: 1, scrollTo: target});
        }
    };

    return (
        <Section className={styles.header}>
            <div className="row p-lg-4 pt-4 d-flex align-items-center">
                <div className="col-6 col-lg-5 offset-lg-1 align-items-center d-flex">
                    <Logo />
                </div>
                <div className="col-6 col-lg-5 d-flex align-items-center justify-content-end">
                    <div className="d-none d-lg-flex mr-5 align-items-center">
                        <a href="#" className={styles.link} onClick={event => handleScroll(event, '#nav-demo')}>The App</a>
                        <span className={styles.bar}>|</span>
                        <a href="#" className={styles.link} onClick={event => handleScroll(event, '#benefit-heading')}>Benefits</a>
                        <span className={styles.bar}>|</span>
                        <a href="#" className={styles.link} onClick={event => handleScroll(event, '#pricing')}>Pricing</a>
                        <span className={styles.bar}>|</span>
                        <a href="#" className={styles.link} onClick={event => handleScroll(event, '#nav-features')}>Features</a>
                        <span className={styles.bar}>|</span>
                        <a href="#" className={styles.link} onClick={event => handleScroll(event, '#nav-footer')}>Contact</a>
                    </div>
                    {/*<Button*/}
                    {/*    handleClick={handleClick}*/}
                    {/*    reverse={true}*/}
                    {/*    className={styles.cta}*/}
                    {/*>*/}
                    {/*    Get early access*/}
                    {/*</Button>*/}
                </div>
            </div>
        </Section>
    );

};

export default Header
