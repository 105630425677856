import React from "react";
import Section from "./section";
import styles from "./footer.module.scss";
import Obfuscate from "react-obfuscate";
import LogoText from "../components/logoText";
import globals from "../globals";

const Footer = () => (

    <Section className={styles.footer}>
        <div className="row" id="nav-footer">
            <div className={`col-12 col-lg-8 offset-lg-2 ${styles.wrapper}`}>
                <div className="row no-gutters">
                    <div className="col-11 col-lg-4 pl-5 pl-lg-0 mb-5">
                        <LogoText fill="#ffffff" />
                        <div className={styles.expression}>/hoss-telle-ify/</div>
                        <div className={styles.noun}>noun</div>
                        <div className={styles.definition} dangerouslySetInnerHTML={{__html: globals.noWidows('A (pretty sweet) web app for your hostel to help bring your information into the hands of your guests.')}} />
                    </div>
                    <div className="col-12 col-lg-6 offset-lg-2 text-center text-lg-right">
                        <div className="d-inline-block text-lg-left">
                            <h6 className="mb-2">Get in touch</h6>
                            <span>E: <Obfuscate email="hello@hostelify.app" /></span>
                            <br />
                            <span>P: <Obfuscate tel="+61290648471" /> (AU)</span>
                        </div>
                    </div>
                </div>
                <div className={styles.bottom}>Hostelify {new Date().getFullYear()} &copy; All rights reserved.</div>
            </div>
        </div>
    </Section>

);

export default Footer;