import React, { useState } from "react";
import { useMedia } from "react-use";
import PropTypes from "prop-types";
import styles from "./button.module.scss";
import { gsap } from "gsap";

const Button = (props) => {

    const [iconSrc, setIcon] = useState(props.iconDefault);
    const button = React.createRef();
    const bg = React.createRef();
    const bgUnderlay = React.createRef();
    const icon = React.createRef();
    const tl = gsap.timeline();
    const isMobile = useMedia('(max-width: 992px)');

    const handleMouseEnter = (e) => {
        e.stopPropagation();
        tl.clear();
        tl.fromTo(bgUnderlay.current, {
            scale: 0,
            display: 'block'
        }, {
            duration: 0.1,
            transformOrigin: "center center",
            scale: 1
        });
        tl.to(button.current, {
            duration: 0.1,
            css: {color: props.reverse ? '#FFFFFF' : '#ff6e75'}
        });
        tl.add(() => {
            setIcon(props.iconHover);
        }, '-=0.1');
        tl.set(bg.current, {display: 'none'});
    }

    const handleMouseLeave = (e) => {
        e.stopPropagation();
        tl.clear();
        tl.set(bg.current, {display: 'block'});
        tl.to(bgUnderlay.current, {
            scale: 0,
            duration: 0.1,
            transformOrigin: "center center",
        });
        tl.to(button.current, {
            duration: 0.1,
            css: {color: !props.reverse ? '#FFFFFF' : '#ff6e75'}
        }, 0);
        tl.add(() => {
            setIcon(props.iconDefault);
        }, 0);
        tl.set(bgUnderlay.current, {display: 'none'});
    }

    const Icon = <span>&nbsp;&nbsp;<span ref={icon}>{iconSrc}</span></span>;

    return (
        <div
            ref={button}
            className={`${styles.button} ${props.className} ${props.reverse ? styles.reverse : ''}`}
            onClick={props.handleClick}
            onKeyDown={props.handleClick}
            onMouseEnter={!isMobile ? handleMouseEnter.bind(this) : () => {}}
            onMouseLeave={!isMobile ? handleMouseLeave.bind(this) : () => {}}
        >
            <div className={styles.text}>
                {props.children}
                {props.iconDefault ? Icon : ''}
            </div>
            <div ref={bg} className={styles.bg} />
            <div ref={bgUnderlay} className={styles.bgUnderlay} />
        </div>
    );
    
}

Button.propTypes = {
    handleClick: PropTypes.func.isRequired,
    reverse: PropTypes.bool,
    className: PropTypes.string,
    iconDefault: PropTypes.object,
    iconHover: PropTypes.object
};

Button.defaultProps = {
    className: ''
};

export default Button;