import React from "react";
import PropTypes from "prop-types"
import styles from "./section.module.scss";

const Section = (props) => {

    return (
        <section className={props.className} id={props.id ? props.id : ''}>
            <div className={`${styles.inner} ${props.innerClassName}`}>
                {props.children}
            </div>
        </section>
    );

};

Section.propTypes = {
    className: PropTypes.string,
    innerClassName: PropTypes.string
};

export default Section;